import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Header = ({ siteTitle }) => (
  <header className="header">
    <div
      style={{
        padding: `1.45rem 0`,
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `center`,
      }}
    >
      <div style={{ display: `flex`, alignItems: `center` }}>
        <img
          className="brand"
          alt="Johnson Firm Logo"
          src="https://res.cloudinary.com/dcnlacxzw/image/upload/v1623176957/Johnson%20Firm/CLEAR_PNG_JF_-_Secondary_-_All_White_qcr1xp.png"
        />
        <h1 style={{ margin: `0 0 0 1em` }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div>

      <a className="link" href="https://ljemailsigs.sanity.studio/desk">
        Manage
      </a>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
